import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Card, FormControl, InputGroup, Dropdown, DropdownButton, Nav } from "react-bootstrap";
import Slider from "react-slick";
import * as Icon from 'react-bootstrap-icons';
import app_flow_img from "../assets/app-flow.png"
import logo_1 from '../assets/logo-1.png';
import icon_1 from '../assets/icons/Frame.png';
import third_section_icon_1 from '../assets/icons/Future of Secure Payments/Icon 1.png';
import third_section_icon_2 from '../assets/icons/Future of Secure Payments/Icon 2.png';
import third_section_icon_3 from '../assets/icons/Future of Secure Payments/Icon 3.png';
import third_section_icon_4 from '../assets/icons/Future of Secure Payments/Icon 4.png';
import third_section_icon_5 from '../assets/icons/Future of Secure Payments/Icon 5.png';
import buyer_icon_1 from '../assets/icons/Secured by SolSafe/Buyer Icons/Icon 1.png';
import buyer_icon_2 from '../assets/icons/Secured by SolSafe/Buyer Icons/Icon 2.png';
import buyer_icon_3 from '../assets/icons/Secured by SolSafe/Buyer Icons/Icon 3.png';
import buyer_icon_4 from '../assets/icons/Secured by SolSafe/Buyer Icons/Icon 4.png';
import buyer_icon_5 from '../assets/icons/Secured by SolSafe/Buyer Icons/Icon 5.png';
import escrow_icon from '../assets/icons/escrow_icon.png';
import feature_icon_1 from '../assets/icons/Secured by SolSafe/Seller Icons/Icon 1.png'
import feature_icon_2 from '../assets/icons/Secured by SolSafe/Seller Icons/Icon 2.png'
import feature_icon_3 from '../assets/icons/Secured by SolSafe/Seller Icons/Icon 3.png'
import feature_icon_4 from '../assets/icons/Secured by SolSafe/Seller Icons/Icon 4.png'
import feature_icon_5 from '../assets/icons/Secured by SolSafe/Seller Icons/Icon 5.png'
import sixth_section_icon_1 from '../assets/icons/Enhances Your Web 2.0 Business Growth/3.1. Icon WIth BG/Image 1.png'
import sixth_section_icon_2 from '../assets/icons/Enhances Your Web 2.0 Business Growth/3.1. Icon WIth BG/Image 2.png'
import sixth_section_icon_3 from '../assets/icons/Enhances Your Web 2.0 Business Growth/3.1. Icon WIth BG/Image 3.png'
import sixth_section_icon_4 from '../assets/icons/Enhances Your Web 2.0 Business Growth/3.1. Icon WIth BG/Image 4.png'
import sixth_section_icon_5 from '../assets/icons/Enhances Your Web 2.0 Business Growth/3.1. Icon WIth BG/Image 5.png'
import sixth_section_icon_6 from '../assets/icons/Enhances Your Web 2.0 Business Growth/3.1. Icon WIth BG/Image 6.png'
import partner_1 from "../assets/partners-logo/pinksale_solsafe.webp"
import partner_2 from "../assets/partners-logo/dexview_solsafe.webp"
import partner_3 from "../assets/partners-logo/Raydium_solsafe.webp"
import partner_4 from "../assets/partners-logo/Dextools_solsafe.webp"
import partner_5 from "../assets/partners-logo/Vault-X-LOGO.png"
import startup_partner_1 from "../assets/startup-partners/aws-activate.png"
import startup_partner_2 from "../assets/startup-partners/google-startup.png"
import startup_partner_3 from "../assets/startup-partners/microsoft-startup.png"
import tick_icon from '../assets/Checkmark.png'
import solsafe_seal_img from "../assets/SOLSAFESEAL.png";
import dashboard_img from "../assets/Dashboard.png";
import Fee_icon_img from "../assets/Fees.png"
import integration_img from "../assets/no-integration.png"
import smart_page from "../assets/smart-page.png"
import widget from "../assets/widget-frame.png"
import landing_page from "../assets/landing-page.png"
import './home.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

const HomePage: React.FC = () => {
    const isMobile = useIsMobile();
    const FeatureSlider = useRef<Slider | null>(null);
    const [isForSellers, setIsForSellers] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState('seller'); // Default selection
    const [dropdownTitle, setDropdownTitle] = useState("I'm Selling Product / Service");
    const [dropdownCurrency, setDropdownCurrency] = useState("SOL");
    const [zoomEffect, setZoomEffect] = useState('zoom-in');


    const videoId = 't8rrC_mc23I';

    // Construct the embed URL using the video ID
    const videoSrc = `https://www.youtube.com/embed/${videoId}`;

    const handleSelectSellerOrBuyer = (eventKey: any) => {
        setDropdownTitle(eventKey);
    };

    const handleSelectCurrency = (eventKey: any) => {
        setDropdownCurrency(eventKey);
    };

    const handlePlanChange = (event: any) => {
        setSelectedPlan(event.target.value);
    };


    const features = [
        {
            icon: feature_icon_1, // Replace with your icon paths
            title: 'Guaranteed Payment',
            description: 'Know your funds are secure and ready for disbursement upon successful delivery.'
        },
        {
            icon: feature_icon_2, // Replace with your icon paths
            title: 'Boost Sales and Conversions',
            description: 'Gain buyer commitment upfront, reducing abandoned orders.'
        },
        {
            icon: feature_icon_3, // Replace with your icon paths
            title: 'Cost Savings',
            description: 'Lower logistics costs and eliminate the risks associated with pay/cash-on-delivery.'
        },
        {
            icon: feature_icon_4, // Replace with your icon paths
            title: 'Proof of Delivery',
            description: 'Verifiable delivery ensures your protection.'
        },
        {
            icon: feature_icon_5, // Replace with your icon paths
            title: 'Effortless Integration and Monitoring',
            description: 'Utilize a merchant dashboard for real-time transaction oversight.'
        },
        // ... other features
    ];

    const features_buyer = [
        {
            icon: buyer_icon_1, // Replace with your icon paths
            title: 'Secure Payments',
            description: "Your funds are protected until you're satisfied with the order."
        },
        {
            icon: buyer_icon_2, // Replace with your icon paths
            title: 'Fraud Protection',
            description: 'Say goodbye to payment scams and ensure seller accountability.'
        },
        {
            icon: buyer_icon_3, // Replace with your icon paths
            title: 'Quality Assurance',
            description: ' Only pay for items that meet the agreed specifications and quality.'
        },
        {
            icon: buyer_icon_4, // Replace with your icon paths
            title: 'No Risk, No Stress',
            description: "If your order is incorrect, damaged, or undelivered, you don't pay."
        },
        {
            icon: buyer_icon_5, // Replace with your icon paths
            title: 'Easy Refunds and Tracking',
            description: "Enjoy a straightforward refund process if terms aren't met, with a dashboard to track transactions."
        },
        // ... other features
    ];



    const features_1 = [
        {
            id: 1,
            title: 'Transparent Pricing',
            description: 'Enjoy a straightforward pricing model with no hidden costs. Pay only for successful transactions, with competitive rates that support your growth.',
            imageUrl: sixth_section_icon_1,
        },
        {
            id: 2,
            title: 'Developer Tools',
            description: 'Leverage our extensive APIs, plugins, and libraries designed for seamless integration, empowering developers to focus on innovation.',
            imageUrl: sixth_section_icon_4,
        },
        {
            id: 3,
            title: '#NoCode Movement',
            description: "Solsafe's #NoCode solutions offer a straightforward path to adopt advanced payment capabilities, no coding required.",
            imageUrl: sixth_section_icon_3,
        },
        {
            id: 4,
            title: 'AI Fraud Prevention',
            description: "Utilize AI to detect and mitigate fraud, significantly reducing Return to Origin (RTO) losses and safeguarding your transactions.",
            imageUrl: sixth_section_icon_2,
        },
        // {
        //     id: 5,
        //     title: 'Market Expansion',
        //     description: " By making transactions with anonymous users secure and trustworthy, SOLSAFE opens up new avenues for your business to engage with a global audience. This expansion capability is crucial for tapping into the vast potential of the crypto market.",
        //     imageUrl: sixth_section_icon_5,
        // },
        // {
        //     id: 6,
        //     title: 'Innovative Payment Solutions',
        //     description: "Embrace the future by offering your customers the option to pay with cryptocurrency. SOLSAFE’s innovative payment solutions cater to the crypto-savvy consumer, setting your business apart and ahead in competitive markets.",
        //     imageUrl: sixth_section_icon_6,
        // }
    ];


    const [activeFeature, setActiveFeature] = useState(features_1[0]);
    // Specify the type of elements the refs are pointing to
    const featureRefs = useRef<Array<React.RefObject<HTMLDivElement>>>(features_1.map(() => React.createRef<HTMLDivElement>()));
    const containerRef = useRef<HTMLDivElement>(null); // Specifically for a div element

    const handleFeatureClick = (feature: any, index: any) => {

        if (activeFeature.id !== feature.id) {
            setZoomEffect('zoom-out'); // Apply zoom-out effect
            setTimeout(() => {
                setActiveFeature(feature); // Set the active feature after zoom-out effect
                setZoomEffect('zoom-in'); // Apply zoom-in effect
            }, 300); // Adjust the duration as needed to match your transition time
        }


        setTimeout(() => {
            const targetFeatureElement = featureRefs.current[index]?.current;

            if (targetFeatureElement) {
                targetFeatureElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }, 100);
    };


    useEffect(() => {
        const activeFeatureIndex = features_1.findIndex(feature => feature.id === activeFeature.id);
        const activeFeatureRef = featureRefs.current[activeFeatureIndex]?.current;
        if (activeFeatureRef && containerRef.current) {
            containerRef.current.scrollTop = activeFeatureRef.offsetTop - containerRef.current.offsetTop;
        }
    }, [activeFeature, features_1]);


    const toggle = () => {
        setIsForSellers(prevState => !prevState);
    };

    const [openFaqIndex, setOpenFaqIndex] = useState(null);

    const toggleOpen = (index: any) => {
        setOpenFaqIndex(openFaqIndex === index ? null : index);
    };

    const future_slider_settings = {
        infinite: false,
        slidesToShow: 3,
        speed: 500,
        arrows: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    const handleSelect = (eventKey: any) => {
        alert(`Selected option: ${eventKey}`);
        // Perform actions based on selection
    };

    const partners = [
        { name: 'Partner 1', image: partner_1 },
        { name: 'Partner 2', image: partner_2 },
        { name: 'Partner 3', image: partner_3 },
        { name: 'Partner 4', image: partner_4 },
        // Add more partners as needed
    ];

    return (
        <div className="container-fluid">
            <section className="first-section section-padding position-relative">
                <Container className="justify-content-space-between section-container">
                    <Row>
                        {/* Image Column - This should come first on mobile and second on web */}
                        <Col xs={12} sm={5} className="order-2 order-sm-2 d-flex justify-content-center mt-5 mt-sm-0">
                            <img className='process-flow-img' src={app_flow_img} alt="App Flow" />
                        </Col>
                        {/* Content Column - This should come second on mobile and first on web */}
                        <Col xs={12} sm={7} className='order-1 order-sm-1 d-flex justify-content-center'>
                            <div>
                                <div className='f-s-subtitle'>
                                    ESCROW-POWERED <Icon.ShieldCheck size={20} />
                                </div>
                                <div className='f-s-title mt-4'>
                                    <div>Send/Receive</div>
                                    <div>
                                        Payment Links with <span className='f-s-title-2'>Trust</span>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <InputGroup className="input-with-dropdown">
                                        <Dropdown  >
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%' }}>
                                                {dropdownTitle}
                                                <Icon.ChevronDown className='ms-2' size={23} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='type-selection-dropdown-menu'>
                                                <Dropdown.Item onClick={() => handleSelectSellerOrBuyer("I'm Selling Product / Service")} eventKey="I'm Selling Product / Service">
                                                    I'm Selling Product / Service
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleSelectSellerOrBuyer("I'm Buying Product / Service")} eventKey="I'm Buying Product / Service">
                                                    I'm Buying Product / Service
                                                </Dropdown.Item>
                                                {/* Add more options as needed */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <DropdownButton
                                            variant="outline-primary"
                                            title={dropdownTitle}
                                            id="sell-button"
                                            align="end"
                                            onSelect={handleSelectSellerOrBuyer}
                                            style={{ width: '100%' }}
                                        >

                                            <Dropdown.Menu>
                                                <Dropdown.Item className="sell-button-dropdown" eventKey="I'm Selling Product / Service" href="#">I'm Selling Product / Service</Dropdown.Item>
                                                <Dropdown.Item eventKey="I'm Buying Product / Service" href="#">I'm Buying Product / Service</Dropdown.Item>

                                            </Dropdown.Menu>
                                        </DropdownButton> */}
                                    </InputGroup>
                                </div>
                                <div className='mt-3'>
                                    <InputGroup className="input-with-dropdown">
                                        <InputGroup.Text className="label for-label">For $</InputGroup.Text>
                                        <FormControl
                                            aria-label="Amount (to the nearest dollar)"
                                            className="text-input"
                                            placeholder="5"
                                        />
                                        <Dropdown onSelect={handleSelectCurrency}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {dropdownCurrency}
                                                <Icon.ChevronDown className="ms-2" size={23} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="SOL">SOL</Dropdown.Item>
                                                <Dropdown.Item eventKey="USDC">USDC</Dropdown.Item>
                                                {/* Add more options as needed */}
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        {/* <DropdownButton
                                            variant="outline-primary"
                                            title={dropdownCurrency}
                                            id="input-group-dropdown-2"
                                            align="end"
                                            className="dropdown-button"
                                            onSelect={handleSelectCurrency}
                                            style={{ width: '80%' }} // Adjusted width to 80%
                                        >
                                            <Dropdown.Item eventKey="SOL" href="#">SOL</Dropdown.Item>
                                            <Dropdown.Item eventKey="BUSD" href="#">BUSD</Dropdown.Item>
                                           
                                        </DropdownButton> */}
                                    </InputGroup>
                                </div>
                                <div className='mt-3'>
                                    <button className='g-s-btn'>DApp Launching Soon</button>
                                </div>
                                <div className='social-link-container'>
                                    <div className='social-link-heading'>
                                        Connect With Us
                                    </div>
                                    <Nav className="home-social">
                                        <Nav.Link href="https://twitter.com/SolSafePay" target='_blank' className="mx-2"><Icon.TwitterX size={24} /></Nav.Link>
                                        {/* <Nav.Link href="#youtube" className="mx-2"><Icon.Discord size={24} /></Nav.Link> */}
                                        <Nav.Link href="https://t.me/+osBIsZYXzRJmNGQ1" target='_blank' className="mx-2"><Icon.Telegram size={24} /></Nav.Link>
                                    </Nav>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="about" className="second-section-1 section-padding position-relative">
                <Container className="justify-content-space-between">
                    <Row>
                        <Col sm={12} className='d-flex justify-content-center'>
                            <div>
                                <div className='s-s-title'>
                                    Instant Payment Links
                                </div>
                                <div className='s-s-title-1 mt-2'>
                                    Create Payment Links in Just 30 Seconds
                                </div>
                                <div className='s-s-subtitle mt-3'>
                                    Generate unique payment links in seconds, streamlining your sales process with unparalleled ease and efficiency
                                </div>
                                <div className='mt-5'>
                                    <img src={dashboard_img} className='me-4 dashboard-img' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="partner-section  section-padding position-relative">
                <Container className="justify-content-space-between section-container">
                    <Row>
                        <Col sm={12}>
                            <div>
                                <div className='s-s-title color-white'>
                                    Powering Innovation Together With
                                </div>
                                <div className="logos-container startup-partners">
                                    <img src={startup_partner_3} alt="Microsoft" />
                                    <img src={startup_partner_1} alt="AWS" />
                                    <img src={startup_partner_2} alt="Google" />

                                    {/* Add more logos as needed */}
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section id="about" className="second-section section-padding position-relative">
                <Container className="justify-content-space-between">
                    <Row>
                        <Col sm={12} className='d-flex justify-content-center'>
                            <div>
                                <div className='s-s-title'>
                                    SOLSAFE in 60 Seconds
                                </div>
                                <div className='s-s-title-1 mt-2'>
                                    Secure Payments Simplified
                                </div>
                                <div className='s-s-subtitle mt-3'>
                                    Learn how Solsafe is transforming the payment landscape with escrow-secured, easy crypto solutions.
                                </div>


                                <div className='s-s-card mt-5 video-container'>
                                    <iframe
                                        src={videoSrc}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="SOLSAFE in 60 Seconds" // Use a meaningful title for accessibility
                                        className="youtube-video" // Add this class if you need to apply custom styles
                                    ></iframe>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="features" className="third-section section-padding position-relative">
                <Container className="justify-content-space-between section-container">
                    <Row>
                        <Col sm={12}>
                            <div>
                                <div className='s-s-title color-white'>
                                    Unlock the Future of Payments With
                                </div>
                                <div className='s-s-title-1 mt-2 color-yellow'>
                                    Solsafe's Suite of Solutions
                                </div>
                                <div className='s-s-subtitle mt-3 color-font-pink'>
                                    Whether you're buying your morning coffee or managing an online store, Solsafe’s suite of products delivers a seamless, secure transaction experience that caters to your needs.
                                </div>
                                <div className='mt-5'>
                                    <Slider className="trending-slider-container" ref={FeatureSlider}  {...future_slider_settings}>
                                        <Card className="payment-card">
                                            <Card.Header className="payment-card-header">
                                                <img src={third_section_icon_1} />
                                                <h5>QR Code  <br /> Payments</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Title>Instant Secure Transactions</Card.Title>
                                                <Card.Text>
                                                    A single scan ensures fast, fraud-free transactions,
                                                    empowering your business with the speed and security of blockchain technology. Ideal for retail and events, where convenience meets confidence.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <Card className="payment-card">
                                            <Card.Header className="payment-card-header">
                                                <img src={third_section_icon_3} />
                                                <h5>Payment<br />Links</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Title>Versatile Payment Solutions</Card.Title>
                                                <Card.Text>
                                                    Generate a secure link in seconds, share it across any digital channel,
                                                    and accept payments with ease. Expand your reach and cater to a global audience without ever compromising on security.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <Card className="payment-card">
                                            <Card.Header className="payment-card-header">
                                                <img src={third_section_icon_2} />
                                                <h5>WooCommerce & Shopify  <br />Plugins</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Title>E-Commerce Made Easy</Card.Title>
                                                <Card.Text>
                                                    Integrate the power of crypto payments into your online store with Solsafe’s WooCommerce and Shopify Plugins.
                                                    Seamlessly accepting a range of cryptocurrencies, these plugins transform your e-commerce platform into a hub of innovation and inclusivity.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <Card className="payment-card">
                                            <Card.Header className="payment-card-header">
                                                <img src={third_section_icon_4} />
                                                <h5>Showcase Your <br /> Brand</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Title>Customized Payment Experiences</Card.Title>
                                                <Card.Text>
                                                    Elevate your brand with customizable payment pages that resonate with your identity.
                                                    Solsafe's 'Showcase Your Brand' feature allows you to personalize the payment experience, enhancing customer trust and reinforcing your brand’s aesthetics.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <Card className="payment-card">
                                            <Card.Header className="payment-card-header">
                                                <img src={third_section_icon_5} />
                                                <h5>Solsafe
                                                    <br /> Dashboard</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Title>Your Operations Command Center</Card.Title>
                                                <Card.Text>
                                                    Gain insights and take control with the Solsafe Dashboard. Track transactions, manage disputes, and delve into analytics—all from one central hub.
                                                    Designed for both merchants and buyers, the dashboard simplifies your financial oversight, making management as intuitive as it is intelligent.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        <Card className="payment-card">
                                            <Card.Header className="payment-card-header">
                                                <img src={third_section_icon_5} />
                                                <h5>Fraud
                                                    <br /> Detection</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Title>Proactive Protection</Card.Title>
                                                <Card.Text>
                                                    Stay ahead of threats with Solsafe's AI-driven Fraud Detection.
                                                    Leveraging cutting-edge technology, we provide a protective shield for your transactions, ensuring each one is secure from scams and unauthorized activities, so you can focus on growing your business with peace of mind.
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Slider>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="how-it-works" className="fourth-section section-padding position-relative">
                <Container className="justify-content-space-between">
                    <Row>
                        <Col sm={12}>
                            <div>
                                <div className='s-s-title'>
                                    How SOLSAFE <span className='s-s-title-1'>Works?</span>

                                </div>

                                <div className='s-s-subtitle mt-3'>

                                    Discover how our escrow system safeguards every transaction, ensuring peace of mind for buyers and sellers alike.

                                </div>
                            </div>

                        </Col>
                        <Col sm={12} className='mt-4'>
                            <Container fluid className="process-flow-container">
                                <Row className="justify-content-center">
                                    <Col md={4} className="process-step-card first">
                                        <div className='process-step d-flex'>
                                            <div className="step-number">1</div>
                                            <div className='ms-3'>
                                                <h5>Create Paylink/Pages</h5>
                                                <p className='step-sub-heading'>Either Buyer or Seller initiates by creating Paylinks, outlining project specifics, and setting up an escrow contract on Solana</p>
                                                {/* <p className='step-text'> The buyer and seller agree on the terms of the sale, including price and delivery conditions. This agreement is then input into the SOLSAFE platform, initiating the escrow process.
                                                </p> */}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} className="process-step-card first">
                                        <div className='process-step d-flex'>
                                            <div className="step-number">2</div>
                                            <div className='ms-3'>
                                                <h5>Share Paylink</h5>
                                                <p className='step-sub-heading'>Once created, either party can share the Paylink, ensuring both buyer and seller agree on the terms before proceeding</p>
                                                {/* <p className='step-text'>The buyer deposits the payment into the SOLSAFE escrow account. These funds are securely held and not released until the transaction terms are fully met, ensuring protection for both parties.
                                                </p> */}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} className="process-step-card first third">
                                        <div className='process-step d-flex'>
                                            <div className="step-number">3</div>
                                            <div className='ms-3'>
                                                <h5>Receive Funds in Escrow</h5>
                                                <p className='step-sub-heading'>The buyer sends payment through the shared Paylink, and SOLSAFE holds it securely in escrow, service delivery.</p>
                                                {/* <p className='step-text'>The seller delivers the product or service according to the agreed terms. For digital goods or services, SOLSAFE can automatically verify delivery using its integrated AI capabilities.</p> */}
                                            </div>
                                        </div>


                                    </Col>
                                </Row>
                                <Row className="justify-content-center mt-2 flex-column-reverse flex-md-row">
                                    <Col md={4} className="process-step-card second order-md-3">
                                        <div className='process-step d-flex'>
                                            <div className="step-number">6</div>
                                            <div className='ms-3'>
                                                <h5>Resolve Disputes</h5>
                                                <p className='step-sub-heading'>Should disagreements arise, either the buyer or seller can initiate SOLSAFE's dispute resolution, aligning with the established terms</p>
                                                {/* <p className='step-text'>In cases where the buyer is not satisfied, SOLSAFE offers an AI-assisted dispute resolution process. The platform reviews the transaction details, and agreement terms, and provides evidence to propose a fair resolution to both parties.</p> */}
                                            </div>
                                        </div>


                                    </Col>
                                    <Col md={4} className="process-step-card second order-md-2">
                                        <div className='process-step d-flex'>
                                            <div className="step-number">5</div>
                                            <div className='ms-3'>
                                                <h5>Release Funds</h5>
                                                <p className='step-sub-heading'>After transaction confirmation by the receiving party, SOLSAFE releases the escrowed funds to the delivering party</p>
                                                {/* <p className='step-text'>Upon buyer confirmation, the payment is released from the escrow account to the seller. This step concludes the transaction, ensuring the seller receives payment only after fulfilling the agreed-upon conditions.</p> */}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4} className="process-step-card second order-md-1">
                                        <div className='process-step d-flex'>
                                            <div className="step-number">4</div>
                                            <div className='ms-3'>
                                                <h5>Deliver & Confirm</h5>
                                                <p className='step-sub-heading'>The delivering party fulfills the agreed terms, while the receiving party verifies and confirms the transaction's success on SOLSAFE</p>
                                                {/* <p className='step-text'>Once the buyer receives and is satisfied with the product or service, they confirm acceptance on the SOLSAFE platform. For additional security, the AI system can assist in verifying the fulfillment of contract terms.</p> */}
                                            </div>
                                        </div>
                                    </Col>


                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fifth-section section-padding position-relative">
                <Container className="justify-content-space-between section-container">
                    <Row>
                        <Col sm={12}>
                            <div>
                                <div className='mb-3 text-center solsafe-seal-img'>
                                    <img src={solsafe_seal_img} />
                                </div>

                                <div className='s-s-title'>
                                    The Gold Standard of Crypto Payment Processing
                                </div>

                                <div className='s-s-subtitle mt-3'>
                                    Look for the badge that guarantees safety and confidence, ensuring every purchase is protected and trusted.
                                </div>

                                <div className="toggle-container">
                                    <div className="switches-container">
                                        <input
                                            type="radio"
                                            id="seller"
                                            name="switchPlan"
                                            value="seller"
                                            checked={selectedPlan === 'seller'}
                                            onChange={handlePlanChange}
                                        />
                                        <input
                                            type="radio"
                                            id="buyer"
                                            name="switchPlan"
                                            value="buyer"
                                            checked={selectedPlan === 'buyer'}
                                            onChange={handlePlanChange}
                                        />
                                        <label htmlFor="seller">SolSafe For Sellers</label>
                                        <label htmlFor="buyer">SolSafe For Buyers</label>
                                        <div className="switch-wrapper">
                                            <div className="switch">
                                                <div>SolSafe For Sellers</div>
                                                <div>SolSafe For Buyers</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {selectedPlan === 'seller' && (
                                    <div className='switch-data-container mt-5'>
                                        <div className='switch-data-heading'>

                                            With Solsafe, secure and boost your sales through escrow-protected payments.
                                            Enjoy guaranteed funds and reduced costs, streamlining your business growth.
                                        </div>
                                        <div className="feature-list-container">
                                            {features.map((feature, index) => (
                                                <div key={index} className="feature-item">
                                                    <div className='feature-item-img'><img src={feature.icon} alt="" className="feature-icon" /></div>
                                                    <div className="feature-details">
                                                        <h3 className="feature-title">{feature.title}</h3>
                                                        <p className="feature-description">{feature.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {selectedPlan === 'buyer' && (
                                    <div className='switch-data-container mt-5'>
                                        <div className='switch-data-heading'>
                                            Solsafe ensures your purchases are secure and satisfactory, holding payments in escrow until you confirm happiness with your buy.
                                            Shop with ease and confidence.
                                        </div>
                                        <div className="feature-list-container">
                                            {features_buyer.map((feature, index) => (
                                                <div key={index} className="feature-item">
                                                    <div className='feature-item-img'><img src={feature.icon} alt="" className="feature-icon" /></div>
                                                    <div className="feature-details">
                                                        <h3 className="feature-title">{feature.title}</h3>
                                                        <p className="feature-description">{feature.description}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                <div className='fifth-s-second-container mt-5'>
                                    <div className='f-s-s-c-title'>
                                        Every merchant using SolSafe will proudly display “Secured by SolSafe” icon, signaling unshakeable trust to buyers.
                                    </div>
                                    <div className='f-s-s-c-subtitle'>
                                        The "Secured by SolSafe" badge instantly communicates safety to buyers, enhancing their confidence in every transaction. And buyers can shop without worry, knowing their money is protected against non-delivery or incorrect product issues.


                                    </div>
                                    <div className='f-s-s-c-subtitle mt-3'>
                                        With "Secured by SolSafe," we're not just facilitating transactions; we're fostering confidence and reliability in the digital marketplace.
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="" className="ninth-section section-padding position-relative">
                <Container className="justify-content-space-between section-container">
                    <Row>
                        <Col sm={12}>
                            <div>
                                <div className='s-s-title color-white'>
                                    Empowering the Next Millions with  <span className='s-s-title-1 color-yellow'>Web3</span>

                                </div>


                                <div className='s-s-subtitle mt-3 color-font-pink'>
                                    Experience global reach with our escrow payment processor, designed for the worldwide marketplace.
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-5'>
                        <Col sm={7}>
                            <Card className="fee-card-container">
                                <Card.Body>
                                    <Card.Title className="fee-card-title">Transparent Fee:</Card.Title>
                                    <div className='d-flex'>
                                        <div className="fee-card-text">
                                            Just 1%
                                        </div>
                                        <div className='fee-icon'>
                                            <img src={Fee_icon_img} className="fee-icon" />
                                        </div>
                                    </div>

                                    <div className="fee-card-small-text">
                                        No hidden costs.
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={5} className={isMobile ? 'mt-4' : ''}>
                            <Card className='containerIntegration'>
                                <Card.Body>
                                    <div className='headerIntegration'>
                                        No Coding Required
                                    </div>
                                    <div className='subheaderIntegration'>
                                        Effortless Integration to any <br /> Website
                                    </div>
                                    <img src={integration_img} className='imageIntegration' alt="Frame Illustration" />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-4'>

                        <Col sm={5}>
                            <Card className='widget-card'>
                                <Card.Body>
                                    <div className='w-c-text'>
                                        Transform your Woocommerce & Shopify experience with trusted Payments
                                    </div>

                                    <img src={widget} className='w-c-image' alt="Woocommerce Widget" />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={7} className={isMobile ? 'mt-4' : ''}>
                            <Card className='s-p-card'>
                                <Card.Body>
                                    <div className='s-p-head'>
                                        Create Paylinks and Custom Landing Pages
                                    </div>
                                    <div className='s-p-subhead'>
                                        with built-in escrow.
                                    </div>
                                    <img src={landing_page} className='s-p-image' alt="smart-page-image" />
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section id="roadmap" className="seventh-section section-padding position-relative">
                <Container className="justify-content-space-between section-container">
                    <Row>
                        <Col sm={12}>
                            <div>

                                <div className='s-s-title'>
                                    Roadmap
                                </div>

                                {/* <div className='s-s-subtitle mt-3'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry orem has.
                                </div> */}
                            </div>
                            <div className="mt-5">
                                {!isMobile &&
                                    <div className="timeline">
                                        <ul className='timeline-list'>
                                            <li>
                                                <div className="content">

                                                    <p>
                                                        <div className='timeline-content-title'>Phase 1</div>
                                                        <div className='timeline-content-list'>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Website development
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Whitepaper creation
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Prelaunch marketing activities
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Community building efforts
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Token Sale
                                                            </div>
                                                        </div>
                                                    </p>
                                                </div>
                                                <div className="point"></div>
                                                <div className="date">
                                                    <h4>PHASE 1</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="content">

                                                    <p>
                                                        <div className='timeline-content-title'>Phase 2</div>
                                                        <div className='timeline-content-list'>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Releasing Paylink
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                QR Code payment
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Release developer guides
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Introducing split payments
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Onboarding more merchants
                                                            </div>
                                                        </div>
                                                    </p>
                                                </div>
                                                <div className="point"></div>
                                                <div className="date">
                                                    <h4>PHASE 2</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="content">

                                                    <p>
                                                        <div className='timeline-content-title'>Phase 3</div>
                                                        <div className='timeline-content-list'>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Sales analytics dashboard
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Smart pages
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Telegram bot
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Discord bot
                                                            </div>

                                                        </div>
                                                    </p>
                                                </div>
                                                <div className="point"></div>
                                                <div className="date">
                                                    <h4>PHASE 3</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="content">

                                                    <p>
                                                        <div className='timeline-content-title'>Phase 4</div>
                                                        <div className='timeline-content-list'>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Woocommerce Plugin
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Shopify app
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Introducing AI for fraud detection
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Customizable Smart Pages
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <img width={21} height={20} src={tick_icon} className='me-2' />
                                                                Advanced Analytics Features
                                                            </div>
                                                        </div>
                                                    </p>
                                                </div>
                                                <div className="point"></div>
                                                <div className="date">
                                                    <h4>PHASE 4</h4>
                                                </div>
                                            </li>


                                        </ul>
                                    </div>
                                }
                                {isMobile &&
                                    <div className="timeline-mobile">
                                        <ul>
                                            <li>
                                                <div className="bullet green"></div>
                                                <div className="desc">
                                                    <h3>PHASE 1</h3>
                                                    <div className='timeline-content-list'>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Website development
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Whitepaper creation
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Prelaunch marketing activities
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Community building efforts
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Token Sale
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="bullet green"></div>

                                                <div className="desc">
                                                    <h3>PHASE 2</h3>
                                                    <div className='timeline-content-list'>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Releasing Paylink
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            QR Code payment
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Release developer guides
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Introducing split payments
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Onboarding more merchants
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="bullet green"></div>

                                                <div className="desc">
                                                    <h3>PHASE 3</h3>
                                                    <div className='timeline-content-list'>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Sales analytics dashboard
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Smart pages
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Telegram bot
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Discord bot
                                                        </div>

                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="bullet green"></div>
                                                <div className="desc">
                                                    <h3>PHASE 4</h3>
                                                    <div className='timeline-content-list'>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Woocommerce Plugin
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Shopify app
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Introducing AI for fraud detection
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Customizable Smart Pages
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <img width={21} height={20} src={tick_icon} className='me-2' />
                                                            Advanced Analytics Features
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <section className="partner-section section-padding position-relative">
                <Container className="justify-content-space-between section-container">
                    <Row>
                        <Col sm={12}>
                            <div>
                                <div className='s-s-title color-white'>
                                    Our Partners
                                </div>
                                <div className="logos-container">
                                    <img src={partner_1} alt="Pinksale" />
                                    <img src={partner_2} alt="Dexview" />
                                    <img src={partner_3} alt="Raydium" />
                                    <img src={partner_4} alt="Dextools" />
                                    <img src={partner_5} alt='Vaultx' />

                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <section className="sixth-section section-padding position-relative">
                <Container className="justify-content-space-between section-container">
                    <Row>
                        <Col sm={12}>
                            <div>

                                <div className='s-s-title color-white'>
                                    Highlights Of SOLSAFE
                                </div>

                                <div className='s-s-subtitle mt-3 color-font-pink'>
                                    Effortless payments, clear pricing, and advanced security in one powerful platform.
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="split-layout mx-auto">
                        <Col md={6} className="text-section">
                            <div className='s-s-l-s' style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                {features_1.map((feature, index) => (
                                    <div
                                        key={feature.id}
                                        ref={featureRefs.current[index]}
                                        className={`s-s-l-s-d ${activeFeature.id === feature.id ? 'active' : ''}`}
                                        onClick={() => handleFeatureClick(feature, index)}
                                    >
                                        <h2 className='s-s-l-s-d-title'>{feature.title}</h2>
                                        <p className='s-s-l-s-d-text'>{feature.description}</p>
                                    </div>
                                ))}
                            </div>
                        </Col>
                        <Col md={6} className="image-section">
                            <div className={`image-wrapper ${zoomEffect}`}>
                                {activeFeature && (
                                    <img src={activeFeature.imageUrl} alt="Feature" className="img-fluid" />
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="faqs" className="eighth-section section-padding position-relative">
                <Container className="justify-content-space-between">
                    <Row>
                        <Col sm={12}>
                            <div>

                                <div className='s-s-title'>
                                    FAQs for SOLSAFE
                                </div>
                                <div className='s-s-title-1'>
                                    Concepts, Paylinks, and Integrations
                                </div>

                            </div>
                            <div className='faq-container mt-5'>
                                <div className='faq-heading mt-2 mb-5'>Concepts</div>
                                <div key={1} className={openFaqIndex === 1 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(1)}>
                                        Q1. What is Solsafe?
                                        <span className="faq-toggle">{openFaqIndex == 1 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 1 && <div className="faq-answer">A secure platform using escrow to make crypto transactions
                                        safe and simple for everyone.
                                    </div>}
                                </div>
                                <div key={2} className={openFaqIndex === 2 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(2)}>
                                        Q2. What makes SolSafe different from traditional payment gateways?
                                        <span className="faq-toggle">{openFaqIndex == 2 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 2 && <div className="faq-answer">SolSafe leverages blockchain technology for escrow security and offers a range of crypto payment solutions,
                                        making transactions more secure, transparent, and accessible than traditional gateways</div>}
                                </div>
                                <div key={3} className={openFaqIndex === 3 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(3)}>
                                        Q3. How does escrow work on Solsafe?
                                        <span className="faq-toggle">{openFaqIndex == 3 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 3 && <div className="faq-answer">Payments are held in escrow until both buyer and seller are satisfied,
                                        ensuring a secure exchange.
                                    </div>}
                                </div>
                                <div key={4} className={openFaqIndex === 4 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(4)}>
                                        Q4. Which cryptocurrencies can I use on Solsafe?
                                        <span className="faq-toggle">{openFaqIndex == 4 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 4 && <div className="faq-answer">
                                        Solsafe supports SOL, USDC, and more, constantly adding new cryptocurrencies.
                                    </div>}
                                </div>

                                <div key={5} className={openFaqIndex === 5 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(5)}>
                                        Q5. How do I create a Solsafe account?
                                        <span className="faq-toggle">{openFaqIndex == 5 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 5 && <div className="faq-answer">
                                        Sign up easily on our website; just click ‘Sign Up’ and follow the steps.
                                    </div>}
                                </div>

                                <div key={6} className={openFaqIndex === 6 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(6)}>
                                        Q6. Is Solsafe secure?
                                        <span className="faq-toggle">{openFaqIndex == 6 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 6 && <div className="faq-answer">
                                        Yes, with blockchain-based escrow and AI fraud detection, every transaction is safe.
                                    </div>}
                                </div>
                                <div key={7} className={openFaqIndex === 7 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(7)}>
                                        Q7. What fees does Solsafe charge?
                                        <span className="faq-toggle">{openFaqIndex == 7 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 7 && <div className="faq-answer">
                                        We charge 2% of transaction amount for escrow services and maintenance. See our ‘Fees’ section for details.
                                    </div>}
                                </div>
                                <div key={8} className={openFaqIndex === 8 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(8)}>
                                        Q8. What should I do if I have a dispute?
                                        <span className="faq-toggle">{openFaqIndex == 8 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 8 && <div className="faq-answer">
                                        Initiate a dispute through your dashboard for our team to help resolve it.
                                    </div>}
                                </div>
                                <div className='faq-heading mt-5 mb-5'>Paylinks</div>


                                <div key={9} className={openFaqIndex === 9 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(9)}>
                                        Q9. Can I use QR Code Payments for my business?
                                        <span className="faq-toggle">{openFaqIndex == 9 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 9 && <div className="faq-answer">
                                        Yes, QR Code Payments enable instant, secure transactions in-person, with escrow security until delivery confirmation.
                                    </div>}
                                </div>

                                <div key={10} className={openFaqIndex === 10 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(10)}>
                                        Q10. Are Paylinks secure?
                                        <span className="faq-toggle">{openFaqIndex == 10 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 10 && <div className="faq-answer">
                                        Yes, Paylinks are encrypted and securely processed, ensuring each transaction is safe from fraud and unauthorized access.
                                    </div>}
                                </div>

                                <div className='faq-heading mt-5 mb-5'>Integrations</div>

                                <div key={11} className={openFaqIndex === 11 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(11)}>
                                        Q11. Can I customize the Checkout Widget to fit my website’s design?
                                        <span className="faq-toggle">{openFaqIndex == 11 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 11 && <div className="faq-answer">
                                        Absolutely! The Checkout Widget is fully customizable to match your website's look and feel, providing a seamless experience for your customers.
                                    </div>}
                                </div>
                                <div key={12} className={openFaqIndex === 12 ? 'faq-item mt-2 open' : 'faq-item mt-2'}>
                                    <div className="faq-question" onClick={() => toggleOpen(12)}>
                                        Q12. How does the SolSafe Checkout Widget work?
                                        <span className="faq-toggle">{openFaqIndex == 12 ? '−' : '+'}</span>
                                    </div>
                                    {openFaqIndex == 12 && <div className="faq-answer">
                                        The SolSafe Checkout Widget integrates directly into your website, allowing customers to pay with crypto through a simple, user-friendly interface.
                                    </div>}
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </div>
    );
}

export default HomePage;
