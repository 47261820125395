import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons'; 
import { Nav, Navbar, Container, Button } from 'react-bootstrap';
import logo_1 from '../assets/logo-full.png';
import './header.css'

const Header: React.FC = () => {

    const join_telegram = () => {
        window.open("https://t.me/+osBIsZYXzRJmNGQ1", "_blank")
    };

    return (
        <Navbar expand="lg" className="custom-navbar" fixed="top">
            <Container className="justify-content-space-between">
                <Navbar.Brand href="#home" className="me-auto">
                    <img
                        alt="Logo"
                        src={logo_1}
                        width="42"
                        height="48"
                        className="d-inline-block align-top logo-top"
                    />{' '}
                    {/* <span className='brand-name-1'>SOL</span>
                    <span className='brand-name-2'>SAFE</span> */}
                </Navbar.Brand>
                <Navbar.Toggle className='nav-toggler' aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                    <Nav>
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#features">Features</Nav.Link>
                        <Nav.Link href="#how-it-works">How It Works</Nav.Link>
                        <Nav.Link href="#roadmap">Roadmap</Nav.Link>
                        <Nav.Link href="#faqs">FAQs</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <button className="ms-auto btn-get-started" onClick={join_telegram}> Join Telegram <Icon.ArrowRight size={25}></Icon.ArrowRight></button>
            </Container>
        </Navbar>
    )
};

export default Header;