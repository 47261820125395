// AppRoutes.tsx
import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Home from './pages/home';
import Header from './components/header';
import Footer from "./components/footer";

const AppRoutes: FC = () => {
    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                {/* Add more Route components here as needed */}
            </Routes>
            <Footer/>
        </>
    );
};

export { AppRoutes };
