import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import footer_logo from '../assets/footer-logo.png'
import './footer.css';
import { Instagram, Telegram, Discord, Twitch, Twitter, TwitterX } from 'react-bootstrap-icons'; // Assuming you are using react-bootstrap-icons for social icons

const Footer = () => {
  return (
    <footer className="custom-footer">
      <Container className='section-container'>
        <Row className="footer-row">
          <Col className="text-center">
            <img
              src={footer_logo} // Replace with the path to your logo
              alt="SOLSAFE Logo"
              className="footer-logo mb-3"
            />
            <p className="footer-text mb-4">
              Where Payments Meet Peace of Mind
            </p>
            <Nav className="justify-content-center mt-3 mb-4">
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#features">Features</Nav.Link>
              <Nav.Link href="#how-it-works">How It Works</Nav.Link>
              <Nav.Link href="#roadmap">Roadmap</Nav.Link>
              <Nav.Link href="#faqs">FAQs</Nav.Link>
            </Nav>
            <Nav className="justify-content-center mt-4 mb-4 footer-social">
              <Nav.Link href="https://twitter.com/SolSafePay" target='_blank'  className="mx-2"><TwitterX size={24} /></Nav.Link>
              <Nav.Link href="https://t.me/+osBIsZYXzRJmNGQ1" target="_blank" className="mx-2"><Telegram size={24} /></Nav.Link>
            </Nav>
            <div className="footer-bottom-text">
              © 2024 SOLSAFE. All rights reserved.
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
